import { useCountExpiredDays } from '@root/shared/hooks/use-count-expired-days';
import { BodyText } from '@root/shared/ui';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';


const dfnsFormat = format;

interface IProps {
  expireAt: Date;
  format?: 'date' | 'daysLeft';
  isExpiredFromParent?: boolean;
}

export const ExpiredText: FC<IProps> = ({ expireAt, format = 'daysLeft', isExpiredFromParent }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const { t: subscrptionsT } = useTranslation('subscriptions', { keyPrefix: 'card' });

  const { activeDaysLeft, isExpiresSoon, isExpiredDays, isExpired } = useCountExpiredDays(expireAt, isExpiredFromParent);

  if (format === 'date') {
    return (
      <BodyText className={clsx('font-bold text-gray-600 lg:text-[13px]', { '!text-danger-500': isExpired })}>
        {isExpired
          ? subscrptionsT('expiredOn', { date: dfnsFormat(new Date(expireAt), 'MMM dd, yyyy') })
          : subscrptionsT('expiresOn', { date: dfnsFormat(new Date(expireAt), 'MMM dd') })}
      </BodyText>
    );
  }

  return (
    <BodyText className={clsx('font-bold  text-base lg:text-[13px] mb-2 sm:mb-0', isExpiresSoon ? 'text-danger-500' : 'text-gray-600')}>
      {isExpired ? (
        t('expired')
      ) : isExpiredDays ? (
        <span>{t('expires-less')}</span>
      ) : (
        <Trans
          i18nKey='dashboard:bundleInfo.expires-in-days'
          components={{
            days: <span className={clsx(activeDaysLeft > 1 ? 'inline' : 'hidden')} />,
            day: <span className={clsx(activeDaysLeft === 1 ? 'inline' : 'hidden')} />,
          }}
          values={{ count: activeDaysLeft }}
        />
      )}
    </BodyText>
  );
};
