import { QuickActionCard } from '../components/quick-action-card';
import { useQuickActions } from '../hooks/useQuickActions';
import { LayoutContainer } from '@root/infra/layout';
import { PaymentMethodsInfo } from '@root/modules/dashboard/containers/payment-methods-info';
import { H1 } from '@root/shared/ui';
import { useTranslation } from 'react-i18next';

export const QuickActions = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'quickActions' });
  const [{ items }] = useQuickActions();

  return (
    <LayoutContainer className='pb-4'>
      <H1 className='text-2xl font-bold mb-6 lg:mb-4'>{t('title')}</H1>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-[30px]'>
        {Object.keys(items).map((item, index) =>
          item === 'paymentMethods' ? <PaymentMethodsInfo quickActionIcon={items[item].icon}/> : <QuickActionCard {...items[item]} key={`${index}_${items[item]['title']}`} />,
        )}
      </div>
    </LayoutContainer>
  );
};
