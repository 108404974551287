import { BEFORE_EXPIRE_DAYS } from '@root/modules/products/constants/before-expires-days';
import dayjs from 'dayjs';
import { useMemo } from 'react';


export const useCountExpiredDays = (
  expireAt: Date | undefined,
  isExpiredFromParent?: boolean,
): { now: dayjs.Dayjs; expireTime: dayjs.Dayjs; activeDaysLeft: number; isExpiresSoon: boolean; isExpiredDays: boolean; isExpired: boolean } => {
  const dateInfo = useMemo(() => {
    const now = dayjs();
    const expireTime = dayjs(expireAt);
    const activeDaysLeft = dayjs(expireTime).startOf('day').diff(now.startOf('day'), 'day');

    const isExpiresSoon = activeDaysLeft <= BEFORE_EXPIRE_DAYS;

    const isExpiredDays = activeDaysLeft <= 0;

    const isExpired = isExpiredFromParent || (isExpiredDays && dayjs(expireTime).isBefore(now));

    return { now, expireTime, activeDaysLeft, isExpiresSoon, isExpiredDays, isExpired };
  }, [expireAt, isExpiredFromParent]);

  return dateInfo;
};
