import { PriceItem } from '@root/modules/currencies/components/price-item';
import { useBundleInfo } from '@root/modules/dashboard/hooks/useBundleInfo';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { Button } from '@root/shared/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const RenewDropdown: FC<{ bundleItem: IPurchasedProduct }> = ({ bundleItem }) => {
  const { t } = useTranslation();

  // const isDesktop = useIsDesktop();
  const [, { renewSubscription }] = useBundleInfo(bundleItem);
  const bundleDisabled = bundleItem.status === 'Disabled';

  // TODO: delete this code if it is not needed
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const isAnnualSubscription = bundleItem.recurringType === 'Annual';

  // const openDropdown = useCallback(() => {
  //   setIsOpen(true);
  // }, []);

  // const closeDropdown = useCallback(() => {
  //   setIsOpen(false);
  // }, []);


  return (
    <>
      {bundleItem.recurringPrice && (
        <Button
          className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5 renew_button_dashboard capitalize'
          disabled={bundleDisabled}
          onClick={() => renewSubscription(1)}
          color={bundleItem.isExpired ? 'danger' : 'primary'}
        >
          {t('renew')} <PriceItem value={bundleItem.recurringPrice} type='long' />
        </Button>
      )}
    </>
    //   <Popover
    //     isOpen={isOpen}
    //     onClickOutside={closeDropdown}
    //     positions={['bottom', 'top', 'left']}
    //     align={isDesktop ? 'end' : 'center'}
    //     padding={8}
    //     content={
    //       <Card>
    //         <CardHeader className='p-2 bg-[#f4f4f4]'>
    //           <span className='ml-2 font-bold text-gray-600'>{t('renew_for', { ns: 'common' })}</span>
    //         </CardHeader>
    //         <CardFooter>
    //           {bundleItem?.renewalOptions?.map((item) => {
    //             return (
    //               <MenuItem as='button' onClick={() => renewSubscription(item)} key={item} className='flex justify-between w-full cursor-pointer'>
    //                 <span className='capitalize font-bold'>
    //                   {item} {t(item === 1 ? 'month' : 'months', { ns: 'common' })}
    //                 </span>
    //                 <PriceItem value={bundleItem.recurringPrice * item} type='long' />
    //               </MenuItem>
    //             );
    //           })}
    //         </CardFooter>
    //       </Card>
    //     }
    //   >
    //     <Button
    //       className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5 renew_button_dashboard capitalize'
    //       disabled={bundleDisabled}
    //       onClick={openDropdown}
    //       color={bundleItem.isExpired ? 'danger' : 'primary'}
    //     >
    //       {t('renew')}
    //       <ChevronRightIcon
    //         className={clsx('transition cursor-pointer hidden lg:block ml-1', {
    //           '-rotate-90': isOpen,
    //           'rotate-90': !isOpen,
    //         })}
    //       />
    //     </Button>
    //   </Popover>
  );
};
