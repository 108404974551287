import { ExpiredText } from '@root/modules/dashboard/components/bundle-info/expired-text';
import { RenewDropdown } from '@root/modules/dashboard/components/bundle-info/renew-dropdown';
import { useAddonsInfoDropdown } from '@root/modules/dashboard/hooks/useAddonsInfoDropdown';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { BodyTextLarge, Button, Image, LinkButton } from '@root/shared/ui';
import { ArrowLeftWithoutColorIcon } from '@root/shared/ui/icons/arrow-left';
import { FC } from 'react';
import { useTranslation } from "react-i18next";


export const AddonsInfoDropdown: FC<{ items: IPurchasedProduct[] }> = ({ items }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'addonsDropDown' });
  const [{ manageSubscription, getMoreAddons }] = useAddonsInfoDropdown();
  return (
    <>
      {items.map((addon) => (
        <div className='flex justify-between p-4 lg:py-2.5 hover:bg-gray-200 transition border-b border-gray-300 last:border-none'>
          <div className='flex'>
            <div className='hidden sm:flex flex-shrink-0'>
              <div className='border-warning-400 border mr-4 lg:mr-2 overflow-hidden rounded-full self-center'>
                <Image src={addon.imageUrl} alt='product' className='object-cover h-10 w-10' />
              </div>
            </div>
            <div>
              <BodyTextLarge className='text-xl lg:text-base font-bold mb-5 sm:mb-0'>{addon?.title?.text ?? addon?.title}</BodyTextLarge>
              <ExpiredText expireAt={addon.expireAt} />
            </div>
          </div>
          <div className='self-end sm:self-center'>
            <RenewDropdown bundleItem={addon} />
          </div>
        </div>
      ))}
      <div className='px-5 py-4 flex justify-between'>
        <LinkButton as='button' className='mt-auto justify-self-end text-left' onClick={getMoreAddons}>
          {t('get_more_addons')}
          <ArrowLeftWithoutColorIcon className='rotate-180 ml-2' />
        </LinkButton>
        <Button className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5 renew_button_dashboard capitalize' onClick={() => manageSubscription()} color='primary'>
          {t('manage_addons')}
        </Button>
      </div>
    </>
  );
};
