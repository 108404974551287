import defaultBundleImage from '../../../assets/images/products/Bronze_2x.webp';
import { AddonsInfoDropdown } from '@root/modules/dashboard/components/bundle-info/addons-info-dropdown';
import { ExpiredText } from '@root/modules/dashboard/components/bundle-info/expired-text';
import { DashboardUI } from '@root/modules/dashboard/components/dashboard.ui';
import { CancelSubscriptionModal } from '@root/modules/dashboard/components/modals/cancel-subscription';
import { useServiceInfo } from '@root/modules/dashboard/hooks/useServiceInfo';
import { ISeparateService } from '@root/modules/dashboard/types';
import { BodyTextLarge, Button, Image, LoaderFill } from '@root/shared/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const { InfoCard, InfoCardDropdown, InfoCardDropdownButton } = DashboardUI;

export const SeparateServiceInfo: FC<ISeparateService> = (serviceItem) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const { expireAt, separatedServicesData, inactive, addonList } = serviceItem;
  const [{ isModalOpen, isLoading, buttonIsLoading, isOpen }, { openModal, closeModal, handleCancelSubscription, handleEnableSubscription, toggleDropdown }] =
    useServiceInfo(serviceItem);

  if (isLoading) {
    return (
      <InfoCard className='lg:border-r'>
        <LoaderFill />
      </InfoCard>
    );
  }

  return (
    <InfoCard className='lg:border-r'>
      <div className='p-4 bg-gray-100'>
        <div className='flex sm:items-center flex-row justify-between gap-x-4 gap-y-2 flex-wrap'>
          <div className='flex flex-col sm:flex-row items-start'>
            <div className='flex flex-shrink-0 mb-2 sm:mb-0 sm:mt-0.5'>
              <div className='border-warning-400 border mr-4 overflow-hidden rounded-full'>
                <Image src={separatedServicesData.imageUrl || defaultBundleImage} alt='product' className='object-cover h-12 w-12 lg:w-10 lg:h-10' />
              </div>
            </div>
            <div>
              <BodyTextLarge className='text-xl lg:text-base font-bold mb-5 sm:mb-0'>{separatedServicesData.name}</BodyTextLarge>
              {!inactive && expireAt && <ExpiredText expireAt={expireAt} />}
            </div>
          </div>
          <div className='self-end sm:self-center'>
            <Button
              loading={buttonIsLoading}
              className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5 renew_button_dashboard capitalize'
              onClick={inactive ? handleEnableSubscription : openModal}
            >
              {t(inactive ? 'enable_subscription' : 'cancel_subscription')}
            </Button>
          </div>
        </div>
      </div>

      <div className='p-4 pt-1 lg:pt-0 bg-gray-100 '>
        {addonList && addonList.length > 0 && (
          <InfoCardDropdownButton className='upgrade_subscription_button' isOpen={isOpen} toggle={toggleDropdown}>
            {t('show_all_my_addons')}
          </InfoCardDropdownButton>
        )}
      </div>

      {!!addonList?.length && isOpen && <InfoCardDropdown>{<AddonsInfoDropdown items={addonList} />}</InfoCardDropdown>}
      {isModalOpen && !inactive && <CancelSubscriptionModal close={closeModal} onOk={handleCancelSubscription} item={serviceItem} />}
    </InfoCard>
  );
};
