import defaultBundleImage from '../../../assets/images/products/Bronze_2x.webp';
import { ExpiredText } from '../components/bundle-info/expired-text';
import { RecentlyExpired } from '../components/bundle-info/recently-expired';
import { UpgradeOptions } from '../components/bundle-info/upgrade-options';
import { DashboardUI } from '../components/dashboard.ui';
import { useBundleInfo } from '../hooks/useBundleInfo';
import { AddonsInfoDropdown } from '@root/modules/dashboard/components/bundle-info/addons-info-dropdown';
import { RenewDropdown } from '@root/modules/dashboard/components/bundle-info/renew-dropdown';
import { ISeparateService } from '@root/modules/dashboard/types';
import { IPurchasedProduct, IServiceShort } from '@root/modules/products/types/purchased-product';
import { useOutsideClick } from '@root/shared/hooks';
import { BodyTextLarge, Image, Button, LoaderFill } from '@root/shared/ui';
import clsx from 'clsx';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const { InfoCard, InfoCardDropdown, InfoCardDropdownButton } = DashboardUI;

export const BundleInfo: FC<{ bundleItem: (IPurchasedProduct & Pick<ISeparateService, 'addonList'>) | null }> = ({ bundleItem }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const [{ isLoading, isOpen, recentlyExpiredItem }, { toggleDropdown, handleOutsideClick, handleRedirectToShop }] = useBundleInfo(bundleItem);

  useOutsideClick(dropdownWrapperRef, handleOutsideClick, true);

  if (isLoading) {
    return (
      <InfoCard className='lg:border-r'>
        <LoaderFill />
      </InfoCard>
    );
  }

  if (!bundleItem) {
    return (
      <InfoCard className='lg:border-r'>
        <div className='p-4'>
          <BodyTextLarge className='font-bold mb-4 text-xl lg:text-base'>{t('no_bundle.title')}</BodyTextLarge>
          <Button className='py-2.5 self-start px-14' onClick={handleRedirectToShop}>
            {t('no_bundle.button')}
          </Button>
        </div>
      </InfoCard>
    );
  }

  const bundleDisabled = bundleItem.status === 'Disabled';

  return (
    <InfoCard className={clsx({ 'lg:border-r': !isOpen })} status={bundleItem.status} isDropdownOpen={isOpen} ref={dropdownWrapperRef}>
      <div className='p-4 bg-gray-100'>
        <div className='flex sm:items-center flex-row justify-between gap-x-4 gap-y-2 flex-wrap'>
          <div className='flex flex-col sm:flex-row items-start'>
            <div className='flex flex-shrink-0 mb-2 sm:mb-0 sm:mt-0.5'>
              <div className='border-warning-400 border mr-4 overflow-hidden rounded-full'>
                <Image src={bundleItem.imageUrl || defaultBundleImage} alt='product' className='object-cover h-12 w-12 lg:w-10 lg:h-10' />
              </div>
            </div>
            <div>
              <BodyTextLarge className='text-xl lg:text-base font-bold mb-5 sm:mb-0'>{bundleItem?.title?.text ?? bundleItem?.title}</BodyTextLarge>
              <ExpiredText expireAt={bundleItem.expireAt} />
            </div>
          </div>
          <div className='self-end sm:self-center'>
            <RenewDropdown bundleItem={bundleItem} />
          </div>
        </div>
      </div>

      {(bundleItem.canUpgrade || (bundleItem.isAddon && !!bundleItem.addonList?.length)) && (
        <div className='p-4 pt-1 lg:pt-0 bg-gray-100 '>
          <InfoCardDropdownButton className='upgrade_subscription_button' isOpen={isOpen} toggle={toggleDropdown} disabled={bundleDisabled}>
            {t(bundleItem.addonList ? 'show_all_my_addons' : 'Upgrade_subscription_button')}
          </InfoCardDropdownButton>
        </div>
      )}

      {isOpen && (
        <InfoCardDropdown>
          {bundleItem.canUpgrade && <UpgradeOptions item={bundleItem} />}
          {!bundleItem.isAddon && recentlyExpiredItem && <RecentlyExpired item={recentlyExpiredItem} />}
          {bundleItem.isAddon && bundleItem.addonList && <AddonsInfoDropdown items={bundleItem.addonList} />}
        </InfoCardDropdown>
      )}
    </InfoCard>
  );
};
