import { HEADER_HEIGHT } from '@root/infra/layout';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { LinkButton, LinkButtonProps } from '@root/shared/ui';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import clsx from 'clsx';
import { FC } from 'react';
import styled, { css } from 'styled-components';


const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  padding: ${({ theme }) => theme.spacing(4)}px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border-radius: ${({ theme }) => theme.spacing(2.5)}px;
  overflow: hidden;

  @media screen and (min-width: 1140px) {
    border-radius: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(5)}px;
  }
`;

const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  min-height: ${() => `calc(100vh - ${HEADER_HEIGHT}px)`};
`;

const InfoCard = styled.div<{ isDropdownOpen?: boolean; status?: IPurchasedProduct['status']; isQuickAction?: boolean }>`
  min-height: 120px;
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(2.5)}px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray[100]};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1140px) {
    border-radius: ${({ theme, isQuickAction }) => (isQuickAction ? `${theme.spacing(3)}px` : 0)};
    // border-radius: 0;
    overflow: visible;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[300]}`};
    border-color: ${({ theme }) => theme.colors.gray[300]};
    box-shadow: ${({ theme, isDropdownOpen, isQuickAction }) => (isDropdownOpen ? theme.shadows[3] : isQuickAction ? theme.shadows[1] : 'none')};
    z-index: ${({ isDropdownOpen }) => (isDropdownOpen ? 2 : 1)};
    justify-content: space-between;
  }

  ${(props) =>
    props.status === 'Disabled' &&
    css`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(107, 101, 101, 0.22);
        z-index: 999999;
        pointer-events: none;
        cursor: not-allowed;
      }
    `}
`;

const InfoCardDropdown = styled.div`
  background: ${({ theme }) => theme.colors.gray[100]};
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray[300]}`};

  @media screen and (min-width: 1140px) {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 5px 8px 0 rgba(28, 25, 37, 0.2);
    border-bottom-left-radius: ${({ theme }) => theme.spacing(1.5)}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacing(1.5)}px;
    overflow: hidden;
    z-index: 1;
  }
`;

const InfoCardDropdownButton: FC<LinkButtonProps & { isOpen: boolean; toggle: () => void; disabled?: boolean }> = ({ children, isOpen, toggle, disabled }) => {
  return (
    <LinkButton disabled={disabled} className='text-base lg:text-[14px]' onClick={toggle}>
      {children} <ChevronRightIcon className={clsx('ml-2 text-sm', { '-rotate-90': isOpen, 'rotate-0': !isOpen })} />
    </LinkButton>
  );
};

export const DashboardUI = { Card, Page, InfoCard, InfoCardDropdown, InfoCardDropdownButton };
