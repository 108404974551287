import { useRedirection } from "@root/modules/auth/hooks/use-redirection";
import { REDIRECTION_TYPES } from "@root/modules/auth/types";
import { useCallback } from "react";
import { useNavigate } from "react-router";


export const useAddonsInfoDropdown = () => {
	const navigate = useNavigate();
	const { redirect } = useRedirection();


	const manageSubscription = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

	const getMoreAddons = useCallback(() => {
		redirect(REDIRECTION_TYPES.SHOP_ADDONS);
	}, [redirect]);

	const handlers = {
    manageSubscription,
    getMoreAddons,
  };

	return [handlers] as [typeof handlers]
}
